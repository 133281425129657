import React, { createRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { updatePreferences, getPreferences } from "../assets/library/api";
import { searchPeople } from "../CallListContainer/assets/library/api";
import loadingGif from "../../CallButton/assets/images/loading-gif.gif";
import deleteicon from "../CallListContainer/assets/images/deleteicon.svg";
const { REACT_APP_STATIC_ASSETS_BASE_URL } = process.env;

class CallForwarding extends React.Component {
  constructor(props) {
    super(props);
    this.initialForwardingObj = {
      number: "",
      duration: "15",
      name: ""
    };
    this.state = {
      alreadyAvailableNumbers: [],
      forwardedDetails: [],
      forwardingObj: { ...this.initialForwardingObj },
      isApiFetching: true,
      shouldDisplayTable: true,
      contactList: [],
      numberValidated: {
        status: false,
        errorMessage: "Not a valid name or number"
      },
      shouldDisablePreferenceButton: true
    };
    this.typeAheadRef = createRef();
  }

  componentDidMount() {
    const { user } = this.props;
    this.getPreferenceInformation();
    const searchContactResponse = searchPeople(
      user.tenant_id,
      user.subtenant_id,
      user.identity,
      ""
    );
    searchContactResponse.then(async (response) => {
      const prepareOptionsForTypeAheadResponse =
        await this.prepareOptionsForTypeAhead(response.data);
      this.setState({
        ...this.state,
        contactList: [...prepareOptionsForTypeAheadResponse]
      });
    });
  }

  prepareOptionsForTypeAhead = (contactList) => {
    return new Promise((resolve) => {
      const { alreadyAvailableNumbers } = this.state;
      const { user } = this.props;
      const tempContactList = [];
      let currentTreeNode = 0;
      contactList.map((element) => {
        element.subtenant_id.toLowerCase() ===
          user.subtenant_id.toLowerCase() &&
          element.type.toLowerCase() === "user" &&
          element.numbers.map((ele) => {
            if (ele.number && !alreadyAvailableNumbers.includes(ele.number)) {
              const contactObj = { name: "", number: "", type: "" };
              contactObj.name =
                element.name === null ? ele.number : element.name;
              contactObj.label =
                element.name === null
                  ? ele.number
                  : `${element.name} (${ele.number})`;
              contactObj.number = ele.number;
              contactObj.type = ele.type;
              tempContactList.push(contactObj);
            }
          });
        currentTreeNode += 1;
        if (currentTreeNode === contactList.length) {
          resolve(tempContactList);
        }
      });
    });
  };

  setForwardingObj = (e) => {
    const { forwardingObj } = this.state;
    if (e.target.name === "duration") {
      forwardingObj.duration = e.target.value;
    }
    this.setState({
      ...this.state,
      forwardingObj: { ...this.state.forwardingObj }
    });
  };

  getPreferenceInformation = async () => {
    const { user } = this.props;
    const getPreferencesResponse = await getPreferences(
      user.tenant_id,
      user.subtenant_id,
      user.number
    );
    this.setState(
      {
        ...this.state,
        forwardedDetails: [...getPreferencesResponse.data.call_forwarding_rules]
      },
      () => {
        const tempAvaialbleNumbers = [];
        getPreferencesResponse.data.call_forwarding_rules.map((element) => {
          tempAvaialbleNumbers.push(element.number);
        });
        this.setState({
          ...this.state,
          alreadyAvailableNumbers: [...tempAvaialbleNumbers],
          isApiFetching: false
        });
      }
    );
  };

  setForwardedDetailsToState = () => {
    const { forwardingObj } = this.state;
    if (forwardingObj.number === "") {
      this.setState({
        ...this.state,
        numberValidated: { ...this.state.numberValidated, status: true }
      });
    } else {
      this.setState(
        {
          ...this.state,
          forwardedDetails: [...this.state.forwardedDetails, forwardingObj]
        },
        () => {
          this.setState({
            ...this.state,
            forwardingObj: { ...this.initialForwardingObj },
            shouldDisablePreferenceButton: false
          });
          this.typeAheadRef.current.clear();
        }
      );
    }
  };

  saveCallForwardingRules = () => {
    const { forwardedDetails } = this.state;
    const { user } = this.props;
    this.setState({ ...this.state, isApiFetching: true }, async () => {
      const updatePreferencesResponse = await updatePreferences(
        user.tenant_id,
        user.subtenant_id,
        user.number,
        forwardedDetails
      );

      if (updatePreferencesResponse.data === "success") {
        this.getPreferenceInformation();
        this.setState({
          ...this.state,
          shouldDisplayTable: true,
          shouldDisablePreferenceButton: true
        });
      }
    });
  };

  alterDisplay = () => {
    this.setState({ ...this.state, shouldDisplayTable: false });
  };

  setSelected = async (element) => {
    const { forwardingObj } = this.state;
    if (element.length === 0) {
      forwardingObj.number = "";
      forwardingObj.name = "";
    } else {
      const checkIfNumberAlreadyAddedToForwardingListResponse =
        await this.alreadyAddedToForwardingListResponse(element[0].number);

      if (checkIfNumberAlreadyAddedToForwardingListResponse) {
        const { forwardingObj } = this.state;
        forwardingObj.number = element[0].number;
        forwardingObj.name = element[0].name;
        this.setState({
          ...this.state,
          numberValidated: { ...this.state.numberValidated, status: false },
          forwardingObj: { ...this.state.forwardingObj }
        });
      } else {
        this.setState({
          ...this.state,
          numberValidated: {
            status: true,
            errorMessage: "This number already added."
          }
        });
      }
    }
  };

  alreadyAddedToForwardingListResponse = (inputNumber) => {
    return new Promise((resolve) => {
      const { forwardedDetails } = this.state;
      if (forwardedDetails.length === 0) {
        resolve(true);
      } else {
        const alreadyAddedNumbers = forwardedDetails.map(
          (element) => element.number
        );

        if (alreadyAddedNumbers.includes(inputNumber)) {
          resolve(false);
        } else {
          resolve(true);
        }
      }
    });
  };
  removeCallForwardingRules = (indexToBeRemoved) => {
    const { forwardedDetails } = this.state;
    const tempForwardedDetails = [...forwardedDetails];
    tempForwardedDetails.splice(indexToBeRemoved, 1);
    this.setState({
      ...this.state,
      forwardedDetails: [...tempForwardedDetails],
      shouldDisablePreferenceButton: false
    });
  };

  render() {
    const {
      forwardingObj,
      forwardedDetails,
      isApiFetching,
      shouldDisplayTable,
      contactList,
      numberValidated,
      shouldDisablePreferenceButton
    } = this.state;
    const { handleDialer } = this.props;
    return (
      <div className="secondaryParticipant">
        <div className="mainboxheader">
          <div className="popupboxclose" onClick={() => handleDialer()}>
            <span>&#x00d7;</span>
          </div>
          <div className="contactuslist">
            <h4>Preferences</h4>
          </div>
        </div>
        <div className="preferencessec">
          {isApiFetching && (
            <div className="loadingicon">
              <img
                alt="Loading"
                src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${loadingGif}`}
              />
            </div>
          )}
          <div className="callforwardcont">
            {!isApiFetching && (
              <div className="callforwardsec">
                <h4> Call Forwarding</h4>
                <div className="tablesec">
                  {forwardedDetails.map((element, index) => {
                    return (
                      <div className="phonebookbox" key={index}>
                        <div className="firsttext">
                          <div className="Nameletters">{index + 1}</div>
                        </div>
                        <div className="phonebooknum">
                          <div className="mainname">{element.name}</div>
                          <div className="mainnumber">{element.number}</div>
                          <div className="mainnumber">
                            Ring for {element.duration} Seconds
                          </div>
                        </div>
                        <div className="phonebookicon">
                          {
                            <button
                              className="removecolorbut forwardbut"
                              onClick={() =>
                                this.removeCallForwardingRules(index)
                              }
                            >
                              <img
                                alt=""
                                src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${deleteicon}`}
                              />
                            </button>
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
                {shouldDisplayTable && (
                  <button
                    className="forwardbut"
                    onClick={() => this.alterDisplay()}
                    disabled={isApiFetching}
                  >
                    Add New
                  </button>
                )}
              </div>
            )}

            {!isApiFetching && !shouldDisplayTable && (
              <div className="callforwardsec">
                <div className="selectboxsec">
                  <h3>To:</h3>
                  <Typeahead
                    id="basic-example"
                    onChange={(e) => this.setSelected(e)}
                    options={contactList}
                    placeholder="Self or Number or Name"
                    filterBy={["number"]}
                    defaultValue="sandip"
                    ref={this.typeAheadRef}
                  />
                  {numberValidated.status && (
                    <div>
                      <span>{numberValidated.errorMessage}</span>
                    </div>
                  )}

                  {isApiFetching && (
                    <div>
                      <img
                        alt="Loading"
                        src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${loadingGif}`}
                        srcset="Loading"
                      />
                    </div>
                  )}

                  <div className="selectboxsec">
                    <h3>Ring For:</h3>
                    <div className="selecrbox">
                      <select
                        id="duration"
                        name="duration"
                        onChange={(e) => this.setForwardingObj(e)}
                        value={forwardingObj.duration}
                      >
                        <option value="15">3 Rings (15 Seconds)</option>
                        <option value="30">6 Rings (30 Seconds)</option>
                        <option value="45">9 Rings (45 Seconds)</option>
                        <option value="60">12 Rings (60 Seconds)</option>
                        <option value="90">15 Rings (90 Seconds)</option>
                        <option value="120">24 Rings (120 Seconds)</option>
                      </select>
                    </div>
                  </div>
                </div>

                <button
                  className="forwardbut"
                  onClick={() => this.setForwardedDetailsToState()}
                  disabled={isApiFetching}
                >
                  Done
                </button>
                <button
                  className="forwardbut"
                  onClick={() =>
                    this.setState({ ...this.state, shouldDisplayTable: true })
                  }
                  disabled={isApiFetching}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div className="bottomfixbut">
            <button
              className="allsave forwardbut"
              onClick={() => this.saveCallForwardingRules()}
              disabled={shouldDisablePreferenceButton}
            >
              Save Preferences
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CallForwarding;
