import React, { createRef } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import {
  displayContactInitial,
  getGroupUsersName
} from "../../../CallModule/Dialer/CallListContainer/assets/library/helper";
import { checkforUnreadMesage } from "../../../CallModule/Dialer/CallListContainer/assets/library/helper";
import leftarrow from "../../../CallModule/Dialer/CallListContainer/assets/images/leftarrow.svg";
import chatcallicon from "../../../CallModule/Dialer/CallListContainer/assets/images/chatcallicon.svg";
import chatsendicon from "../../../CallModule/Dialer/CallListContainer/assets/images/sendicon.svg";
import msgSentPartial from "../../../Conversation/Chat/ConversationContainer/assets/images/msgSentPartial.svg";
import msgDeliveredAll from "../../../Conversation/Chat/ConversationContainer/assets/images/msgDeliveredAll.svg";
import msgSentAll from "../../../Conversation/Chat/ConversationContainer/assets/images/msgSentAll.svg";
import msgFailed from "../../../Conversation/Chat/ConversationContainer/assets/images/msgFailed.svg";
import msgPending from "../../../Conversation/Chat/ConversationContainer/assets/images/msgPending.svg";
import avataricon from "../../../CallModule/Dialer/CallListContainer/assets/images/avataricon.svg";
import fileIcon from "../../../Conversation/Chat/ConversationContainer/assets/images/fileIcon.svg";

const { REACT_APP_STATIC_ASSETS_BASE_URL } = process.env;

class ConversationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messageText: "", groupConversations: [] };
    this.inputReference = createRef();
  }

  async componentDidMount() {
    const {
      currentConversation,
      alterButtonDiableProperty,
      user,
      conversations
    } = this.props;

    this.groupMessages(currentConversation.messages);
    alterButtonDiableProperty(false);

    let setAllMessagesReadResponse;
    let totalCount = 0;
    if (currentConversation.messages.length > 0) {
      setAllMessagesReadResponse =
        await currentConversation.clientConversation.setAllMessagesRead();
    }
    if (setAllMessagesReadResponse === 0) {
      Object.values(conversations).map((element) => {
        const unreadMessageCount = checkforUnreadMesage(
          element,
          user,
          currentConversation.clientConversation.sid
        );
        totalCount = totalCount + unreadMessageCount;
      });
      if (totalCount > 0) {
        window.setAllUnreadCounts("messages", true);
      } else {
        window.setAllUnreadCounts("messages", false);
      }

      if (
        window.draftMessage &&
        currentConversation.clientConversation.sid in window.draftMessage
      ) {
        this.setState({
          ...this.state,
          messageText:
            window.draftMessage[currentConversation.clientConversation.sid]
        });
      }
    }
  }

  setDraftMessage = (smsText) => {
    const { currentConversation } = this.props;
    if (typeof window.draftMessage === "undefined") {
      window.draftMessage = {
        [currentConversation.clientConversation.sid]: smsText
      };
    } else {
      window.draftMessage[currentConversation.clientConversation.sid] = smsText;
    }
  };

  setMessageText = (e) => {
    const { value } = e.target;
    const { currentConversation } = this.props;
    currentConversation.clientConversation.typing();
    this.setDraftMessage(value);

    this.setState({ ...this.state, messageText: value });
  };

  handleKeyDown = (e, conversation) => {
    if (e.key === "Enter" && !e.shiftKey) {
      this.setState({ ...this.state, messageText: "" });
      e.preventDefault();
      this.sendMessage(conversation);
    }
  };
  sendMessage = (conversation) => {
    const { messageText } = this.state;
    if (messageText.trim() !== "") {
      this.setState({ ...this.state, messageText: "" });
      this.setDraftMessage("");
      const sendMessageResponse = conversation.clientConversation.sendMessage(
        messageText.trim()
      );
      sendMessageResponse.then((response) => {
        conversation.clientConversation.setAllMessagesRead();
      });
    }
  };

  groupMessages = (messages) => {
    let index = 0;
    const groups = messages.reduce((groups, item) => {
      const createdAt = item.created_at.split(" ")[0];
      const date = moment(createdAt).format("MMM DD YYYY");
      if (!groups[date]) {
        groups[date] = [];
      }

      if (
        groups[date].length === 0 ||
        groups[date][groups[date].length - 1][
          groups[date][groups[date].length - 1].length - 1
        ].participantTwilioSid !== item.participant.twilio_sid
      ) {
        index = groups[date].length === 0 ? 0 : groups[date].length;
        groups[date][index] = [
          {
            participantTwilioSid: item.participant.twilio_sid,
            participantIdentity: item.participant.identity,
            participantName: item.participant.contact.name,
            participantNumber: item.participant.contact.number,
            data: []
          }
        ];
      }
      groups[date][index][0]["data"].push(item);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        items: groups[date]
      };
    });
    this.setState(
      { ...this.state, groupConversations: groupArrays },
      () =>
        groupArrays.length > 0 &&
        this.inputReference !== null &&
        this.inputReference.current.scrollIntoView({ behavior: "smooth" })
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { currentConversation } = this.props;
    if (
      currentConversation.messages.length !==
      prevProps.currentConversation.messages.length
    ) {
      this.groupMessages(currentConversation.messages);
    }
  }

  componentWillUnmount() {
    const { currentConversation, setConversationMessages } = this.props;
    setConversationMessages(currentConversation, null);
  }

  displayUserProfileAvatar = (currentConversation) => {
    const { user } = this.props;
    const currentParticipant = getGroupUsersName(
      currentConversation.participants,
      user
    );

    return currentParticipant.name === currentParticipant.number ? (
      <img
        alt=""
        className="chatboxmainavatar"
        src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${avataricon}`}
      />
    ) : (
      displayContactInitial(currentParticipant.name)
    );
  };

  isAChatConversationBasedOnParticipants = () => {
    const { currentConversation } = this.props;
    const participantCount = currentConversation.participants.filter(
      (element) => element.identity === ""
    );

    return participantCount.length;
  };

  getDeliveryStatusIcon = (message) => {
    const isAChatConversationBasedOnParticipantsResponse =
      this.isAChatConversationBasedOnParticipants();
    if (isAChatConversationBasedOnParticipantsResponse === 0) {
      message.delivery.sent = "all";
      message.delivery.delivered = "all";
    }

    if (message.delivery.sent === "none") {
      if (message.delivery.failed === "all") {
        return msgFailed;
      }
      return msgPending;
    }

    if (message.delivery.sent === "some") {
      return msgSentPartial;
    }

    if (message.delivery.sent === "all") {
      if (message.delivery.delivered === "all") {
        return msgDeliveredAll;
      }
      return msgSentAll;
    }
  };

  render() {
    const {
      currentConversation,
      user,
      handleDialer,
      setConversationMessages,
      activeCallCount
    } = this.props;
    const { messageText, groupConversations } = this.state;
    return (
      <div className="col-md-12 p-0">
        <div className="mainboxheader">
          <div className="popupboxclose">
            <div
              className="popupboxclose messgsecclose"
              onClick={() => handleDialer()}
            >
              <span>&#x00d7;</span>
            </div>
            <Button
              className="removecolorbut chartcallicon"
              onClick={() =>
                window.makeOutBoundCall(
                  getGroupUsersName(currentConversation.participants, user)
                    .number,
                  getGroupUsersName(currentConversation.participants, user).name
                )
              }
              disabled={activeCallCount > 0}
            >
              <img
                alt=""
                src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${chatcallicon}`}
              />
            </Button>
          </div>
          <div className="contactuslist">
            <Button
              className="removecolorbut chatboxarrow"
              onClick={() => setConversationMessages(currentConversation, null)}
            >
              <img
                alt=""
                src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${leftarrow}`}
              />
              <div className="firsttext">
                <div className="Nameletters">
                  <span>
                    {this.displayUserProfileAvatar(currentConversation)}
                  </span>
                </div>
              </div>
              <div className="phonebooknum">
                <h4
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title={
                    getGroupUsersName(currentConversation.participants, user)
                      .name
                  }
                >
                  {
                    getGroupUsersName(currentConversation.participants, user)
                      .name
                  }
                </h4>
                <span>
                  {
                    getGroupUsersName(currentConversation.participants, user)
                      .number
                  }
                </span>
              </div>
            </Button>
          </div>
        </div>

        <div className="measgbox">
          {Object.values(groupConversations).map((messageItems, index) => {
            return (
              <div className="dateGroup" key={messageItems.date}>
                <p className="todaydate">{messageItems.date}</p>
                {messageItems.items.map((msgs, index) => {
                  return (
                    <div
                      style={{
                        height: "auto",
                        display: "flex",
                        marginBottom: "20px",
                        flexDirection: "column"
                      }}
                      key={`PID${index}`}
                      className={`${
                        msgs[0].participantIdentity === user.identity
                          ? "sent"
                          : "received"
                      }`}
                    >
                      <div className="firsttext participantBlock">
                        <div className="Nameletters">
                          <span>
                            {msgs[0].participantNumber ===
                            msgs[0].participantName ? (
                              <img
                                alt=""
                                src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${avataricon}`}
                              />
                            ) : (
                              displayContactInitial(msgs[0].participantName)
                            )}
                          </span>
                        </div>
                        <div className="phonebooknum">
                          <span
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={msgs[0].participantName}
                          >
                            {msgs[0].participantName}
                          </span>
                        </div>
                      </div>
                      {msgs[0].data.map((message, index) => {
                        return (
                          <div
                            key={message.twilio_sid}
                            ref={
                              index === msgs[0].data.length - 1
                                ? this.inputReference
                                : ""
                            }
                          >
                            <div className="sendmeasgtext">
                              {message.media !== null ? (
                                message.media.contentType.includes("image") ? (
                                  <img
                                    src={message.body}
                                    alt="MMS"
                                    className="w-100"
                                  />
                                ) : message.media.contentType.includes(
                                    "audio"
                                  ) ? (
                                  <audio controls name="media">
                                    <source
                                      src={message.body}
                                      type={message.media.contentType}
                                    />
                                  </audio>
                                ) : message.media.contentType.includes(
                                    "video"
                                  ) ? (
                                  <video
                                    controls
                                    name="media"
                                    className="w-100"
                                  >
                                    <source
                                      src={message.body}
                                      type={message.media.contentType}
                                    />
                                  </video>
                                ) : (
                                  <a href={message.body} target="_blank">
                                    <img src={fileIcon} className="w-100" />
                                  </a>
                                )
                              ) : (
                                <span>{message.body}</span>
                              )}

                              <span className="dayandtime">
                                <span>
                                  {moment
                                    .utc(message.created_at)
                                    .local()
                                    .format("h:mm A")}
                                </span>
                                <span className="msgdeliverystatus">
                                  <img
                                    alt=""
                                    src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${this.getDeliveryStatusIcon(
                                      message
                                    )}`}
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {getGroupUsersName(currentConversation.participants, user)
          .sms_consent ? (
          <div className=" measgtextbox">
            <textarea
              type="text"
              name="messageToSend"
              id="messageToSend"
              onChange={(e) => this.setMessageText(e)}
              value={messageText}
              onKeyDown={(e) => this.handleKeyDown(e, currentConversation)}
            />
            <Button
              className="removecolorbut chatsendicon"
              onClick={() => this.sendMessage(currentConversation)}
              disabled={messageText === ""}
            >
              <img
                alt=""
                src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${chatsendicon}`}
              />
            </Button>
          </div>
        ) : (
          <div>
            <span style={{ color: "red" }}>
              User has unsubscribed. Messages cant be send.
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default ConversationDetails;
