import React from "react";
import { Button } from "react-bootstrap";
import ConversationContainer from "../ConversationContainer/ConversationContainer";
import searchicon from "../../../CallModule/Dialer/CallListContainer/assets/images/searchicon.svg";
import { checkforUnreadMesage } from "../../../CallModule/Dialer/CallListContainer/assets/library/helper";
const { REACT_APP_STATIC_ASSETS_BASE_URL } = process.env;

class ChatButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInDetailView: false,
      allConversations: {},
      isFiltered: false,
      currentConversationSid: null
    };
  }

  async componentDidMount() {
    const { conversation, existingConversationId } = this.props;
    const res = await this.setMessagesAndCount(conversation);
    if (res) {
      existingConversationId !== null && this.alterDetailView(true);
    }
  }

  setMessagesAndCount = (conversation) => {
    const { allConversations, isFiltered, currentConversationSid } = this.state;
    const { user } = this.props;
    return new Promise((resolve) => {
      Object.keys(conversation.completeList).map((elementIndex, index) => {
        if (elementIndex in allConversations) {
          if (
            conversation.completeList[elementIndex].messages.length !==
            allConversations[elementIndex].messages.length
          ) {
            this.setState({
              ...this.state,
              allConversations: { ...conversation.completeList }
            });
          }
        } else {
          if (!isFiltered) {
            this.setState({
              ...this.state,
              allConversations: { ...conversation.completeList }
            });
          }
        }
        if (index === Object.keys(conversation.completeList).length - 1) {
          resolve(true);
        }
      });
    });
  };

  async shouldComponentUpdate(nextProps) {
    const { conversation, user } = nextProps;
    const { allConversations, isFiltered, currentConversationSid } = this.state;
    this.setMessagesAndCount(conversation);

    return true;
  }

  alterDetailView = (viewStatus, conversationSid) => {
    const { setCurrentConversationSid, conversation } = this.props;
    this.setState({
      ...this.state,
      isInDetailView: viewStatus,
      currentConversationSid: conversationSid,
      allConversations: { ...conversation.completeList }
    });
    setCurrentConversationSid(conversationSid);
  };

  filterConversation = (e) => {
    const { value } = e.target;
    const { conversation, user } = this.props;
    const xx = {};
    if (value !== "") {
      Object.values(conversation.completeList).filter((conversationElement) => {
        conversationElement.participants.map((participantElement) => {
          if (
            participantElement.contact.number !== user.number &&
            (participantElement.contact.name
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0 ||
              participantElement.contact.number
                .toLowerCase()
                .indexOf(value.toLowerCase()) >= 0)
          ) {
            xx[conversationElement.twilio_sid] = conversationElement;
          }
        });
        conversationElement.messages.map((eement) => {
          if (eement.body.toLowerCase().includes(value.toLowerCase())) {
            xx[conversationElement.twilio_sid] = conversationElement;
          }
        });
      });
    }
    this.setState({
      ...this.state,
      allConversations:
        value === "" ? { ...conversation.completeList } : { ...xx },
      isFiltered: value !== ""
    });
  };

  render() {
    const {
      conversation,
      user,
      alterMenu,
      existingConversationId,
      handleDialer,
      alterButtonDiableProperty,
      activeCallCount
    } = this.props;
    const { isInDetailView, allConversations } = this.state;
    return (
      <div>
        <div>
          {!isInDetailView && (
            <div className="mainboxheader">
              <div className="popupboxclose" onClick={() => handleDialer()}>
                <span>&#x00d7;</span>
              </div>

              <div className="contactuslist">
                <h4>Messages</h4>
              </div>
            </div>
          )}
          {!isInDetailView && (
            <div className="searchboxsec">
              <input
                type="text"
                name="filterConversation"
                id="filterConversation"
                placeholder="Search Messages"
                onChange={(e) => this.filterConversation(e)}
              />
              <img
                alt=""
                src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${searchicon}`}
              />
            </div>
          )}
          {!isInDetailView && (
            <Button
              className="removecolorbut plusicon"
              onClick={() => alterMenu(1, "conversation")}
            >
              <p>&#43;</p>
            </Button>
          )}
        </div>
        {Object.keys(allConversations).length > 0 && (
          <div>
            <ConversationContainer
              conversations={allConversations}
              user={user}
              alterDetailView={this.alterDetailView}
              existingConversationId={existingConversationId}
              alterButtonDiableProperty={alterButtonDiableProperty}
              activeCallCount={activeCallCount}
              handleDialer={handleDialer}
            />
          </div>
        )}
        {Object.values(allConversations).length === 0 && (
          <div className="noresult chatbox">No records found</div>
        )}
      </div>
    );
  }
}

export default ChatButton;
