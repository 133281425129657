import React from "react";
import CallButton from "../CallModule/CallButton/CallButton";

class Login extends React.Component {
  constructor(props) {
    super(props);
    const { user, needManualLogin } = this.props;
    this.state = {
      loggedInUser: needManualLogin === "1" ? false : true,
      user
    };
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const { user } = this.state;
    user[name] = value;
    this.setState({ ...this.state, user });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { user } = this.state;
    this.setState({ ...this.state, loggedInUser: true });
    console.log("user ...", user);
  };

  render() {
    const { loggedInUser, user } = this.state;
    return (
      <div>
        {!loggedInUser && (
          <form onSubmit={this.handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <td width="150">Organization ID:</td>
                  <td>
                    <input
                      type="text"
                      name="tenant_id"
                      value={user.tenant_id}
                      onChange={this.handleChange}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>Franchise ID:</td>
                  <td>
                    <input
                      type="text"
                      name="subtenant_id"
                      value={user.subtenant_id}
                      onChange={this.handleChange}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>User ID:</td>
                  <td>
                    <input
                      type="text"
                      name="id"
                      value={user.id}
                      onChange={this.handleChange}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>Name:</td>
                  <td>
                    <input
                      type="text"
                      name="name"
                      value={user.name}
                      onChange={this.handleChange}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>Phone Number:</td>
                  <td>
                    <input
                      type="text"
                      name="number"
                      value={user.number}
                      onChange={this.handleChange}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <input type="submit" value="Login" />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        )}

        {loggedInUser && <CallButton user={user} />}
      </div>
    );
  }
}

export default Login;
