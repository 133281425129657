import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./Components/Login/Login";
import { getLoggedinUserDetails } from "./Components/CallModule/Dialer/assets/library/api";

const App = ({ token }) => {
  const tempAccessToken =
    typeof token === "undefined"
      ? "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL3BhaW50ZXJicm9zc3RhZ2luZy5iMmNsb2dpbi5jb20vdGZwL2Q1NjhjYTk0LTEyYmItNDZkYi05YzY4LWQwZGE1MzgxYWFhZi9iMmNfMV91c2VyL3YyLjAvIiwiZXhwIjoxNjczNTI4MDE0LCJuYmYiOjE2NzM1MjY4MTQsImF1ZCI6ImI0ZTQyM2FiLTYwMWUtNGM2OC04MTZmLWFiZDU2NjQxYzY0OCIsIm9pZCI6IjQ0NDJjMTVlLWQzMzYtNDkzYS04YjViLWIwYWMyMWFmNGU0MiIsInN1YiI6IjQ0NDJjMTVlLWQzMzYtNDkzYS04YjViLWIwYWMyMWFmNGU0MiIsIm5hbWUiOiJUd2lsaW8gQWRtaW4iLCJleHRlbnNpb25fRnJhbmNoaXNlIjoiZGE1ODUzMmItYWM3NC00OTdkLTg5MzUtZDBlODkxY2ZlMjFmIiwiZ2l2ZW5fbmFtZSI6IlR3aWxpbyIsImV4dGVuc2lvbl9yb2xlcyI6IltcIkZyYW5jaGlzZSBBZG1pblwiXSIsImZhbWlseV9uYW1lIjoiQWRtaW4iLCJlbWFpbHMiOlsidHdpbGlvLmZyQG1haWxpbmF0b3IuY29tIl0sInRmcCI6IkIyQ18xX1VzZXIiLCJub25jZSI6IjBmMzBhY2U0LTY1MGEtNGI1OC1iNGVhLTk1OTY2OTllZDRkNCIsInNjcCI6Im9mZmxpbmVfYWNjZXNzIHByb2ZpbGUgdXNlci5yZWFkIiwiYXpwIjoiYjRlNDIzYWItNjAxZS00YzY4LTgxNmYtYWJkNTY2NDFjNjQ4IiwidmVyIjoiMS4wIiwiaWF0IjoxNjczNTI2ODE0fQ.nim6G0pKOtbWTtlqMt7jIaKu1sSkX5iGWIiNuifVcM7IWIwhNsz2npwISXjJcne-wRNfdLnL_5k9sZtWuSU4__G1cZ92sP88d-HWBDJvFpTrLFwk8MeQCyiY57olseESAUhpcvP7yXzYvzF8C1KCkKBn7Ld4v98657n5PGQDRmZ_tLp4ErQaJ132fygEeYjtrvIUH04uRhJNklLSs_WQeGEixjcwyefQeCsv-GnCcdgKuyozAju1Ozsy6f23fFWyLMeJvowfGM8JcD0cRg4BOCt2YEGw4tidZyvfcwdx5M9H50zWU_jOV-49LKsac7f_-XcMNIDIX3moESFKqOMjSg"
      : token.accesstoken;
  const [isApiLoadingData, setIsApiLoadingData] = useState(true);
  const [needManualLogin, setNeedManualLogin] = useState(0);
  const [user, setUser] = useState({
    id: "",
    name: "",
    number: "",
    identity: "",
    tenant_id: "",
    subtenant_id: ""
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    setNeedManualLogin(queryParams.get("login"));
    if (Number(queryParams.get("login")) !== 1) {
      const getLoggedinUserDetailsResponse = await getLoggedinUserDetails(
        tempAccessToken
      );
      const userDataObj = {
        id: getLoggedinUserDetailsResponse.data.data.id,
        name: getLoggedinUserDetailsResponse.data.data.name,
        number: getLoggedinUserDetailsResponse.data.data.number,
        tenant_id: getLoggedinUserDetailsResponse.data.data.tenant_id,
        subtenant_id: getLoggedinUserDetailsResponse.data.data.subtenant_id
      };
      setUser(userDataObj);
      setIsApiLoadingData(false);
    } else {
      const number = queryParams.get("number");
      if (number && number !== null) {
        setUser({ ...user, number: `+${number.trim()}` });
      }
      setIsApiLoadingData(false);
    }
  };

  return (
    <div className="App">
      {!isApiLoadingData && (
        <Login user={user} needManualLogin={needManualLogin} />
      )}
    </div>
  );
};

export default App;
