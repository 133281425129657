import React from "react";
import { Button } from "react-bootstrap";
import EndConferenceButton from "./EndConferenceButton";
import backspace from "../assets/images/backspace.svg";
const { REACT_APP_STATIC_ASSETS_BASE_URL } = process.env;

class KeyPad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pressedKey: ""
    };
  }

  sendDtmfTone = (e) => {
    const { innerHTML: textContent } = e.currentTarget.firstChild;
    const { currentConnection } = this.props;
    const { pressedKey } = this.state;
    this.setState(
      { ...this.state, pressedKey: `${pressedKey}${textContent}` },
      () => currentConnection.sendDigits(textContent)
    );
  };

  clearKeys = () => {
    const { pressedKey } = this.state;
    const xtractedPressedKey = pressedKey.split("");
    xtractedPressedKey.pop();
    this.setState({
      ...this.state,
      pressedKey: `${xtractedPressedKey.join("")}`
    });
  };

  onKeyDownEvent = async (e) => {
    const { key } = e;
    const regexAllowedChars = /^[\d\(\)\+\*\#\-\ ]+$/;
    if (key.toLowerCase() === "backspace") {
      this.clearKeys();
      return true;
    }
    if (regexAllowedChars.test(key)) {
      this.sendDtmfTone({ currentTarget: { textContent: key } });
    }
  };

  render() {
    const {
      toggleKeypad,
      callElement,
      currentConnection,
      user,
      shouldDisableButton,
      alterButtonDiableProperty
    } = this.props;
    const { pressedKey } = this.state;
    return (
      <div
        className="keypadfullsec"
        onKeyDown={this.onKeyDownEvent}
        tabIndex={-1}
      >
        <div className="keypadnumberinputbox">
          <input
            type="text"
            name="keyNumber"
            id="keyNumber"
            disabled
            value={pressedKey}
          />
          {pressedKey !== "" && (
            <Button
              className="removecolorbut backspace"
              onClick={() => this.clearKeys()}
              disabled={shouldDisableButton}
            >
              <img alt="" src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${backspace}`} />
            </Button>
          )}
        </div>
        <div className="grid-container">
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>1</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>2</span>
            <span>A B C</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>3</span>
            <span>D E F</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>4</span>
            <span>G H I</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>5</span>
            <span>J K L</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>6</span>
            <span>M N O</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>7</span>
            <span>P Q R S</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>8</span>
            <span>T U V</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>9</span>
            <span>W X Y Z</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>*</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>0</span>
            <span>+</span>
          </div>
          <div className="grid-item" onClick={(e) => this.sendDtmfTone(e)}>
            <span>#</span>
          </div>
        </div>
        <div className="keypadendcallsec">
          <EndConferenceButton
            callElement={callElement}
            currentConnection={currentConnection}
            user={user}
            conferenaceSid={callElement.conference.sid}
            shouldDisableButton={shouldDisableButton}
            alterButtonDiableProperty={alterButtonDiableProperty}
          />
          <Button
            className="removecolorbut hideicon"
            onClick={() => toggleKeypad()}
          >
            Hide
          </Button>
        </div>
      </div>
    );
  }
}

export default KeyPad;
